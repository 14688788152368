// @flow
import React, {
  type StatelessFunctionalComponent,
} from "react";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import type { ChangeTableFilters, Filters } from "@fas/cpa-state-manager/redux/actions/table";
import en from "dayjs/locale/en-gb";
import { presets } from "../../helpers/generators";
import {
  DateCellComponent,
  FooterComponent,
} from ".";

type Props = {
  filters: Filters,
  onFiltersChange: (Filters) => ChangeTableFilters,
}

const DateRangePickerComponent: StatelessFunctionalComponent<Props> = ({
  filters,
  onFiltersChange,
}: Props) => (
  <DateRangePicker
    // $FlowFixMe filters type contains array but this must be string
    startDate={filters.dateFrom}
    // $FlowFixMe filters type contains array but this must be string
    endDate={filters.dateTo}
    onChangeDate={({
      startDate,
      endDate,
    }: { startDate: string, endDate: string }): ChangeTableFilters => (
      onFiltersChange({ dateFrom: startDate, dateTo: endDate }))}
    onChangePreset={({
      startDate,
      endDate,
    }: { startDate: string, endDate: string }): ChangeTableFilters => (
      onFiltersChange({ dateFrom: startDate, dateTo: endDate }))}
    locale={en}
    presets={presets}
    DateCellComponent={DateCellComponent}
    FooterComponent={FooterComponent}
  />
);

export default DateRangePickerComponent;
